/**
 * Generated bundle index. Do not edit.
 */

export * from './public-api';

export {GalleryCoreComponent as ɵc} from './lib/components/gallery-core.component';
export {GalleryCounterComponent as ɵe} from './lib/components/gallery-counter.component';
export {GalleryDotsComponent as ɵb} from './lib/components/gallery-dots.component';
export {GalleryItemComponent as ɵh} from './lib/components/gallery-item.component';
export {GalleryNavComponent as ɵa} from './lib/components/gallery-nav.component';
export {GallerySliderComponent as ɵd} from './lib/components/gallery-slider.component';
export {GalleryThumbComponent as ɵg} from './lib/components/gallery-thumb.component';
export {GalleryThumbsComponent as ɵf} from './lib/components/gallery-thumbs.component';
export {LazyImage as ɵi} from './lib/directives/lazy-image';
export {TapClick as ɵj} from './lib/directives/tap-click';